/* You can add global styles to this file, and also import other style files */
// @import "styles/_lib";
// @import "styles/_nav";
// @import "styles/_buttons";
// @import "styles/_cards";
// @import "styles/_base";
// @import "styles/_styles";

// TODO CCC: figure out better way to do these styles

@FischbachBlue: #008fd0;

.popover {
    opacity: 1 !important;
}

.swal2-confirm {
    background-color: @FischbachBlue !important;

    &:focus {
        box-shadow: none !important;
        outline: none !important;
    }
}

.skin-blue .main-header .logo,
.skin-blue .main-header .logo:hover,
.skin-blue .main-header .navbar {
    background-color: @FischbachBlue;
}

.skin-blue .left-side,
.skin-blue .main-sidebar,
.skin-blue .wrapper {
    background-color: @FischbachBlue;
}

/* Sidebar text */
.skin-blue .sidebar-menu > li.active > mt-nav-menu-item > a,
.skin-blue .sidebar-menu > li:hover > mt-nav-menu-item > a,
.skin-blue .treeview-menu > li.active > mt-nav-menu-item > a,
.skin-blue .treeview-menu > li > mt-nav-menu-item > a:hover,
.skin-blue .user-panel > .info,
.skin-blue .user-panel > .info > a,
.skin-blue .sidebar a,
.navbar-toggle {
    color: white;
}

/* Sidebar background highlight (no hover)  */
.skin-blue .sidebar-menu > li > .treeview-menu {
    background-color: @FischbachBlue;
}
/* Sidebar background highlight (hover) */
.skin-blue .sidebar-menu > li.active > mt-nav-menu-item > a,
.skin-blue .sidebar-menu > li:hover > mt-nav-menu-item > a {
    background-color: darken(@FischbachBlue, 5%);
}

/* "NAVIGATION" sidebar nav header */
.skin-blue .sidebar-menu > li.header {
    color: white;
    background: @FischbachBlue;
}

.skin-blue .main-header li.user-header {
    background-color: @FischbachBlue !important;
    .sign-out-button {
        background-color: White;
        color: @FischbachBlue;
    }
    p {
        color: white !important;
    }
}

.btn-primary-inverse.active,
.btn-primary-inverse.active:hover,
.btn-primary-inverse.focus.active,
.btn-primary-inverse.focus.active:hover,
.btn-primary-inverse:active:focus,
.btn-primary-inverse:active:hover,
.btn-primary-inverse:hover:not([disabled]) {
    background-color: @FischbachBlue;
}

/* Mobile view hamburger menu button */
.skin-blue .main-header .navbar .sidebar-toggle:hover {
    background-color: @FischbachBlue;
}

.btn-fab-lg,
.btn-fab-md,
.btn-primary,
.btn-success {
    background-color: @FischbachBlue !important;
    color: white;
    border-color: lightgray;
}

.btn-default:hover {
    color: white;
}

tr.archived {
    background-color: rgb(255, 192, 203) !important;
}
tr.archived > td {
    background-color: rgb(255, 192, 203) !important;
}

.app-root-wrapper {
    background-color: @FischbachBlue;
    min-height: 100vh;
}

dynamic-field.transparent-control .form-control {
    background: transparent;
    border: 0px !important;
}

dynamic-field.no-resize .form-control {
    resize: none;
}

.block-submission {
    z-index: 809;
    // z-index of side nav bar is 810
}

.modal-existing-open-order-list-item {
    padding: 20px 10px;
    border-bottom: solid 1px #ccc;
    color: rgba(0, 0, 0, 0.87);
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    box-sizing: border-box;
    background: white;
    font-size: 14px;
}

.modal-existing-open-order-list {
    border: solid 1px #ccc;
    min-height: 60px;
    background: white;
    border-radius: 4px;
    overflow: hidden;
    display: block;
}

.modal-submission-status-change-log {
    width: 75%;
}

/* making all parent overflows visible, so that sticky positioning will work for virtual-scroll-entity-list */

body {
    overflow-x: visible;
    overflow-y: visible;
}

.wrapper {
    overflow-x: visible;
    overflow-y: visible;
}

.table-responsive {
    overflow-x: visible;
}

tr.row-on-hold > td {
    background: rgb(267, 267, 206) !important;
}

tr:hover.row-on-hold > td {
    background: darken(rgb(267, 267, 206), 20%) !important;
}

tr.row-archived > td {
    background: rgb(242, 148, 148) !important;
}

.submissions-archive-column {
    text-align: center;
    vertical-align: middle !important;
}

table.header-above-checkboxes thead {
    z-index: 1;
}

submission-additional-notes div.miles-card dynamic-field ng-component dynamic-form-input-wrapper dynamic-form-label .errortext {
    display: none;
}

.cursor-pointer {
    cursor: pointer;
}

.text-align-center {
    text-align: center;
}
